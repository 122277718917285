<template>
  <b-modal
    id="modal-manage-catalog"
    title="Manage Product Catalog"
    centered
    scrollable
    hide-header-close
    @hidden="resetSelectedGroup"
  >
    <template v-if="catalogGroups.length == 0">
      There is no data
    </template>
    <!-- OLD (Bug UI when label for input is multi-line) -->
    <!-- <div class="reseller-group-catalog-list" v-else>
      <label class="cursor-pointer" v-for="group in catalogGroups" :key="group.id">
        <input type="checkbox" class="cursor-pointer" :value="group.id" v-model="currentGroupIds" /> {{ group.name }}
      </label>
    </div> -->
    <!-- NEW -->
    <b-form-checkbox-group
      id="checkbox-catalog-group"
      v-model="currentGroupIds"
      name="checkbox-catalog-group"
      class="reseller-group-catalog-list"
      v-else
    >
      <b-form-checkbox :value="group.id" v-for="group in catalogGroups" :key="group.id" class="cursor-pointer">{{ group.name }}</b-form-checkbox>
    </b-form-checkbox-group>
    <!-- Custom Modal Footer -->
    <template #modal-footer>
      <div class="flex-grow-1">
        <b-button class="btn-success" @click="addNewGroup">Add Group</b-button>
      </div>
      <b-button class="btn-wz-cancel" @click="$bvModal.hide('modal-manage-catalog')">Cancel</b-button>
      <b-button class="btn-wz-proceed" @click="onSubmit" :disabled="!isUpdated">Save</b-button>
    </template>
  </b-modal>
</template>

<script>
import { toaster } from "@/_helpers"

export default {
  name: "ModalManageCatalog",
  props: ['existInGroup', 'productId'],
  data() {
    return {
      catalogGroups: [],
      currentGroupIds: [],
      oldGroupIds: [],
    }
  },
  computed: {
    // true => if there's change for the selected catalog group(s)
    isUpdated: function () {
      let oldData = [...this.oldGroupIds].sort((a, b) => a - b)
      let currentData = [...this.currentGroupIds].sort((a, b) => a - b)

      return JSON.stringify(oldData) != JSON.stringify(currentData)
    }
  },
  watch: {
    existInGroup() {
      this.currentGroupIds = []
      this.setInitialData()
    }
  },
  mounted() {
    this.getCatalogGroupList()
    this.setInitialData()
  },
  methods: {
    async onSubmit() {
      if(!this.isUpdated) return // If there's no update for selected catalog group(s)

      let dataToBeAdded = [] // JSON body for add product to catalog group(s)
      let dataToBeDeleted = [] // JSON body for delete product to catalog group(s)

      let addedToGroupIds = [] // Array of string for group(s) name for added product
      let deletedFromGroupIds = [] // Array of string for group(s) name for deleted product product
      
      // Check added product for catalog group(s)
      for await (var currentGroupId of this.currentGroupIds) {
        if (!this.oldGroupIds.includes(currentGroupId)) {
          var groupName1 = this.catalogGroups.find(el => el.id == currentGroupId)?.name // Group(s) name for the added product
          
          dataToBeAdded.push({
            product_ids : this.productId,
            reseller_product_choose_group_id : currentGroupId
          })

          addedToGroupIds.push(groupName1) // Push the group name by currentGroupId for toast message
        }
      }
      if (addedToGroupIds.length) {
        var resAddToGatalog = await this.$api.catalog.addProductToCatalogGroup(dataToBeAdded)
        if (resAddToGatalog.status === 200) toaster.make(`Product has been added to ${addedToGroupIds.join(', ')}`, "success")
        else toaster.make(resAddToGatalog.data.message, "danger")
      }

      // Check deleted product from catalog group(s) 
      for await (var oldGroupId of this.oldGroupIds) {
        if (!this.currentGroupIds.includes(oldGroupId)) {
          const productChooseId = this.existInGroup.find(el => el.id == oldGroupId)?.reseller_product_choose_id // Get product choose id
          var groupName2 = this.existInGroup.find(el => el.id == oldGroupId)?.name // Group(s) name for the deleted product
          
          dataToBeDeleted.push({
            product_choose_ids : productChooseId,
            reseller_product_choose_group_id : oldGroupId 
          })

          deletedFromGroupIds.push(groupName2) // Push the group name by currentGroupId for toast message
        }
      }

      if (deletedFromGroupIds.length) {
        var resDeleteFromCatalog = await this.$api.catalog.deleteProductFromCatalogGroup(dataToBeDeleted)
        if (resDeleteFromCatalog.status === 200) toaster.make(`Product has been deleted from ${deletedFromGroupIds.join(', ')}`, "success")
        else toaster.make(resDeleteFromCatalog.data.message, "danger")
      }
     
      // If there's a change for the product catalog group(s) => refresh product for updating the "existInGroup" prop
      if(resAddToGatalog?.status === 200 || resDeleteFromCatalog?.status === 200){
        this.$emit("refreshProduct")
        this.$bvModal.hide("modal-manage-catalog")
      } 
    },
    async getCatalogGroupList() {
      try {
        const res = await this.$api.catalog.getCatalogGroupList();
        if (res.status === 200) this.catalogGroups = res.data.data
      } catch (error) {
        console.error(error)
      }
    },
    async setInitialData() {
      if (this.existInGroup.length == 0) return
      for await (var group of this.existInGroup) {
        if (group.reseller_product_choose_id != null)
        this.currentGroupIds.push(group.id)
      }
      this.oldGroupIds = this.currentGroupIds
    },
    addNewGroup() {
      if(this.catalogGroups.length >= 12) toaster.make("Sorry, you can only add up to 12 groups", "warning") 
      else this.$bvModal.show('modal-add-catalog-group')
    },
    resetSelectedGroup(){
      this.currentGroupIds = this.oldGroupIds
    },
  },
};
</script>
<style scoped>
button {
  padding: 8px 12px;
  text-transform: uppercase;
  font-size: 12px !important;  
  letter-spacing: .5px !important;
}

.reseller-group-catalog-list {
  display: grid;
}

@media only screen and (min-width: 660px) {
  .reseller-group-catalog-list {
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: .5rem;
  }
}
</style>
